import { css } from '@emotion/react';
import { useState } from 'react';

import { ImportPortfolioFlow } from '@/ui/container/portfolioConnect';
import MobileFeaturePrompt from '@/ui/layout/mobileFeaturePrompt';

import { MarketingButton } from '@/marketing/components/common/button';
import { CheckIcon, ExploreQuant, JoinTelegram, Portfolio, UserIcon, VisitNewsFeed } from '@/marketing/constants/icon';

export const FeatureRightSideSection = ({
    title = 'Real-time market news',
    list = ['5+ Intraday powerful quant signals', '3 Monthly Rebalance basket'],
    iconSection = {
        icon: <UserIcon height={24} width={24} />,
        text: `200K+ users have signed up on investmint`,
    },
    ctaIcon,
    ctaText = 'Use this feature',
    ctaLink = null,
}) => {
    const [show, setShow] = useState(false);

    const connectPortfolioCTA = ctaText === 'Connect portfolio now';
    return (
        <>
            {show && connectPortfolioCTA && (
                <ImportPortfolioFlow
                    onClose={() => {
                        setShow(false);
                    }}
                />
            )}
            {show && !connectPortfolioCTA && <MobileFeaturePrompt prompt={`${ctaText} on`} closeFn={() => setShow(false)} />}
            <h2 className={'md:text-center1 text-56 font-800 leading-1.3 tracking-1 md:text-32'}>{title}</h2>

            <div className="md:text-center1 mt-12 text-20 leading-2 tracking-1  text-black-200">
                {list.map((item, index) => (
                    <div className="flex items-center gap-12 md:items-start md:text-18" key={index}>
                        <span className="md:mt-8">
                            {' '}
                            <CheckIcon />
                        </span>{' '}
                        <span>{item}</span>
                    </div>
                ))}
            </div>

            <div className="mt-20">
                <MarketingButton
                    type="primary"
                    extraCSS={css`
                        border-radius: 10px;
                        background: linear-gradient(180deg, #5667ff 0%, #3145f9 100%), linear-gradient(180deg, #a82be3 0%, #7809bc 100%);
                        box-shadow: 0px 0px 0px 3px rgba(78, 96, 254, 0.1);
                        padding: 0;
                        height: 50px;
                        min-width: 196px;

                        @media screen and (max-width: 768px) {
                            min-width: 100%;
                        }
                    `}
                >
                    <div
                        css={css`
                            padding: 10px 16px 8px 16px;
                            height: 50px;
                        `}
                        className="flex h-full w-full items-center justify-center gap-8"
                        onClick={() => {
                            if (ctaLink) {
                                // open cta link in new tab
                                window.open(ctaLink, '_blank');
                            } else {
                                setShow(true);
                            }
                        }}
                    >
                        {ctaIcon()}
                        <span className="text-16 font-600">{ctaText}</span>
                    </div>
                </MarketingButton>
            </div>
            <div className="mt-20 flex h-full items-center  gap-12 text-16 leading-2 tracking-1  text-black-200">
                {iconSection.icon}
                <span>{iconSection.text}</span>
            </div>
            {/* <div className="mt-20 flex items-center gap-12">
                <ZerodhaIcon />
                <UpstoxIcon />
                <GrowwIcon />
                <HDFCIcon />
                <span className=" flex items-center gap-12  text-20 leading-2 tracking-1  text-black-200">+ 10 more brokers</span>
            </div> */}
        </>
    );
};
export const cardContent = [
    {
        content: (
            <FeatureRightSideSection
                title="Continuous insights on your stocks"
                list={[
                    `Fastest news on stocks`,
                    `AI summarised actionable insights`,
                    `Digests on your stock portfolio`,
                    `Easily buy or sell stocks inside the app`,
                ]}
                ctaIcon={VisitNewsFeed}
                ctaText="Visit news feed"
                iconSection={{
                    icon: <UserIcon height={24} width={24} />,
                    text: `1,00,000+ minters enjoy this feature everyday!`,
                }}
            />
        ),
        lottieFile: 'https://res.cloudinary.com/invesmint/raw/upload/v1692820429/lottie/aiNews_p2lfvd.json',
        tagline: 'Real-time market news',
        subtText: 'Act fast with actionable news powered by AI',
    },
    {
        content: (
            <FeatureRightSideSection
                title="Ditch your broker app"
                list={[
                    `Get a 10x better view of your holdings`,
                    `Automatic portfolio refresh every 24 hours`,
                    `Relevant and easy to setup price alerts`,
                    `Unlink and delete your data anytime`,
                ]}
                iconSection={{
                    icon: <UserIcon height={24} width={24} />,
                    text: `6,545+ users have connected portfolios`,
                }}
                ctaIcon={Portfolio}
                ctaText="Connect portfolio now"
            />
        ),
        lottieFile: 'https://res.cloudinary.com/invesmint/raw/upload/v1692820429/lottie/portfolio_exelvf.json',
        tagline: 'Connected portfolio',
        subtText: 'Take control of your holdings by receiving timely updates',
    },
    {
        content: (
            <FeatureRightSideSection
                title="Data backed trading models"
                list={[
                    `10+ systematic quant baskets`,
                    `Advisory-free & fully automated alerts`,
                    `Build your own trading models`,
                    `Trade with 18+ brokers in-app`,
                ]}
                ctaIcon={ExploreQuant}
                ctaText="Explore quant models"
                iconSection={{
                    icon: <UserIcon height={24} width={24} />,
                    text: `98,545+ users trade these baskets`,
                }}
            />
        ),
        lottieFile: 'https://res.cloudinary.com/invesmint/raw/upload/v1692820429/lottie/quant_y9hvcf.json',
        tagline: 'Data backed trading models',
        subtText: 'Trade easy with timely entry and exit signals',
    },
    {
        content: (
            <FeatureRightSideSection
                title="Join the buzzing community"
                list={[
                    `Speak with our most active members`,
                    `Ask market related queries to experts`,
                    `Real time support from Investmint team`,
                    `Market related updates and humour`,
                ]}
                iconSection={{
                    icon: <UserIcon height={24} width={24} />,
                    text: `5000+ users have already joined`,
                }}
                ctaLink="https://telegram.me/investmintclub"
                ctaIcon={JoinTelegram}
                ctaText="Join on telegram"
            />
        ),
        lottieFile: 'https://res.cloudinary.com/invesmint/raw/upload/v1692820429/lottie/telegram_j7okdb.json',
        tagline: 'Buzzing community',
        subtText: 'Learn & grow with a community of 5,000+ traders',
    },
];
