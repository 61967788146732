import { css } from '@emotion/react';

import { GlassPattern, PatterBackgroundWithEffect } from '@/marketing/components/common/bg/glassEffect';
import { QrCode, StarIconApp } from '@/marketing/constants/icon';
import { CONTAINERS } from '@/marketing/constants/styles';
import { motion } from 'framer-motion';

export function TrustSection() {
    return (
        <PatterBackgroundWithEffect pattern={<GlassPattern />}>
            <div css={CONTAINERS.WIDTH_1320_16_PADDING}>
                <div className={'flex flex-col items-center pt-72  md:pt-52 '}>
                    <h2 className={'mt-20 text-56 font-800 leading-1.3 tracking-1 md:text-center md:text-32'}>We believe in honesty</h2>
                    <p className={'mt-8 text-20 font-400 text-black-200 md:text-center  md:text-16'}>Place your trust in 100% transparent data</p>
                </div>

                <div className={'mt-56 flex pb-48 leading-2 md:pb-22'} css={gridBox}>
                    <FeatureBox
                        css={css`
                            grid-area: left;
                            height: 100%;
                            border-right: 1px solid #e4e4e4;
                            padding: 16px 36px;
                            @media screen and (max-width: 768px) {
                                border-right: none;
                            }
                        `}
                        icon={() => (
                            <img
                                src={'https://i.imgur.com/urPwlqi.png'}
                                css={css`
                                    height: 44px;
                                `}
                            />
                        )}
                        title={() => (
                            <div className="text-24  font-600 leading-none md:text-20">
                                <span className="text-24 font-700  leading-none md:text-20  md:font-600">Seed round $2 million</span>
                            </div>
                        )}
                        desc={() => <div className="text-center text-14 font-400  text-black-200">in 2022, with many other prominent angels</div>}
                    />

                    <FeatureBox
                        css={css`
                            grid-area: center;
                            height: 100%;
                            border-right: 1px solid #e4e4e4;
                            padding: 16px 36px;
                            @media screen and (max-width: 768px) {
                                border-right: none;
                            }
                        `}
                        icon={() => (
                            <div className="flex items-center gap-8">
                                {[
                                    {
                                        link: 'https://m.economictimes.com/tech/funding/trading-startup-investmint-raises-2-million-in-funding-led-by-nexus-venture-partners/amp_articleshow/94937157.cms',
                                        image: '/assets/img/marketing/et.png',
                                    },
                                    {
                                        link: 'https://www.bloomberg.com/press-releases/2022-10-18/signal-based-trading-platform-investmint-raises-2-million-in-seed-funding-led-by-nexus-venture-partners',
                                        image: '/assets/img/marketing/bloomberg.png',
                                    },
                                    {
                                        link: 'https://inc42.com/buzz/trading-startup-investmint-bags-funding-to-build-product-for-investors-traders/',
                                        image: '/assets/img/marketing/inc.png',
                                    },
                                ].map(({ link, image }) => {
                                    return (
                                        <a href={link} target="_blank">
                                            <img
                                                src={image}
                                                css={css`
                                                    height: 36px;
                                                    object-fit: contain;
                                                `}
                                            />
                                        </a>
                                    );
                                })}
                            </div>
                        )}
                        title={() => (
                            <div className="text-24  font-600 leading-none md:text-20">
                                <span className="text-24 font-700 leading-none md:text-20   md:font-600">Media coverage</span>
                            </div>
                        )}
                        desc={() => <div className="text-14 font-400 leading-none  text-black-200">covered by top financial media in India</div>}
                    />

                    <FeatureBox
                        css={css`
                            grid-area: right;
                            height: 100%;

                            padding: 16px 36px;
                        `}
                        icon={() => (
                            <div className="flex items-center gap-4">
                                <StarIconApp />
                                <StarIconApp />
                                <StarIconApp />
                                <StarIconApp />
                                <StarIconApp />
                            </div>
                        )}
                        title={() => (
                            <div className="text-24  font-600 leading-none md:text-20">
                                <span className="text-24 font-700 leading-none  md:text-20 md:font-600">1000+ app store ratings</span>
                            </div>
                        )}
                        desc={() => <div className="text-center text-14 font-400  text-black-200">positive & honest comments from our users</div>}
                    />
                </div>

                <div className="mb-52 "></div>
                {/* <div className="mt-44 mb-92 flex justify-center text-center text-14 md:mb-72 md:mt-12 md:text-13 md:leading-1.9">
                    We never force/prompt you to trade.
                    <br /> You have control over how you want to participate with Quant and News signals.
                </div> */}
            </div>
        </PatterBackgroundWithEffect>
    );
}

const gridBox = css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0;
    grid-template-areas: 'left center right';

    @media screen and (max-width: 768px) {
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
`;

function FeatureBox({ icon = QrCode, title, desc, className, ...props }: { icon?: any; title: any; desc: any; className?: any; css: any }) {
    return (
        <motion.div
            initial={{ opacity: 0, x: 12 }}
            animate={{ opacity: 1, x: 0 }}
            className={`flex h-full w-full flex-col items-center justify-center py-12 px-30  ${className}`}
            {...props}
        >
            <div>{icon()}</div>
            <div className="mt-32">{title()}</div>
            <div className="mt-12 md:mt-12">{desc()}</div>
        </motion.div>
    );
}
