import Script from 'next/script';

export const OGTags = () => {
    return (
        <>
            <meta charSet="UTF-8" />
            <meta name="description" content="Now trade and invest with fully data-backed, and systematic trading models." />
            <meta property="og:title" content="Investmint - #OwnYourPnL in stock markets" />
            <meta property="og:description" content="Download Investmint app and turbocharge your stock trading and investments" />

            <meta property="og:image" content="https://res.cloudinary.com/invesmint/image/upload/v1665930924/og_image_whierj.jpg" />
            <meta property="twitter:title" content="Investmint | India's First Signal Based Trading App | Systematic Trading" />
            <meta property="twitter:description" content="Now trade and invest with fully data-backed, and systematic trading models." />
            <meta property="twitter:image" content="https://res.cloudinary.com/invesmint/image/upload/v1665930924/og_image_whierj.jpg" />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            {/* Include Facebook Pixel */}
            <Script async src="https://connect.facebook.net/en_US/fbevents.js" />
        </>
    );
};
