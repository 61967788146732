import React from 'react';
import styled from 'styled-components';

import { SCREEN_NAME } from '@/utils/routingUtils';
import { EVENT_TYPE, trackEvent } from '@/utils/trackingUtils';

import { QrCode } from '@/marketing/constants/icon';

export const ModalAppStoreInfo: () => JSX.Element = () => (
    <React.Fragment>
        <div className="mt-16 md:mt-20 ">
            <QrCode height={120} width={120} color="#2c2b2b" />
        </div>

        <div className="mt-40 text-center text-18">Download app from</div>
        <div className="mt-12 flex gap-20 md:flex-col">
            <a
                onClick={() => {
                    trackEvent(EVENT_TYPE.BUTTON_CLICKED, {
                        button_name: 'download_ios_app',
                        from_screen: SCREEN_NAME.qr_code_screen,
                    });
                }}
                target={'_blank'}
                href="https://investmint.onelink.me/TjeE/qste66gu"
            >
                <AppStoreImage height={'40px'} style={{ height: '32px' }} src="/assets/img/common/app_store.png" />
            </a>

            <a
                onClick={() => {
                    trackEvent(EVENT_TYPE.BUTTON_CLICKED, {
                        button_name: 'download_android_app',
                        from_screen: SCREEN_NAME.qr_code_screen,
                    });
                }}
                target={'_blank'}
                href="https://investmint.onelink.me/TjeE/de6kc62t"
            >
                <AppStoreImage height={'40px'} style={{ height: '32px' }} src="/assets/img/common/gpay.png" />
            </a>
        </div>
    </React.Fragment>
);

const AppStoreImage = styled.img`
    @media screen and (max-width: 648px) {
        height: 48px !important;
        width: 100%;
    }
`;
