import { css } from '@emotion/react';

import { GridBackgroundStyle } from '@/marketing/components/common/bg/graph';
import { QRScanBox } from '@/marketing/components/common/qrScanBox';
import { AppStoreButton, PlayStoreButton } from '@/marketing/components/homepage/hero/button';
import { NavBar } from '@/marketing/components/homepage/hero/navbar';
import { QrCode, StarIconBlue, Turnover, UserIcon } from '@/marketing/constants/icon';
import { CONTAINERS } from '@/marketing/constants/styles';
import { motion } from 'framer-motion';

export const HeroSection = () => {
    return (
        <GridBackgroundStyle>
            <NavBar />
            <motion.div css={CONTAINERS.WIDTH_1320_16_PADDING}>
                <div className="mt-72 flex flex-col items-center">
                    <h1 className="md:tracking1 text-center text-72 font-800 leading-none tracking-1-neg md:text-40 md:tracking-4-neg">
                        #OwnYourPnL
                        <br /> in stock markets
                    </h1>
                    <p
                        className="leading-1.4 mt-20 text-center text-24 font-500 tracking-1 text-black-200 md:mt-14 md:text-20"
                        css={css`
                            @media screen and (max-width: 768px) {
                                max-width: 380px;
                            }
                        `}
                    >
                        Turbocharge your stock trading and investing journey
                        <br /> with the Investmint app
                    </p>
                </div>
                <div className="mt-52 flex flex-col items-center md:hidden">
                    <QRScanBox />
                </div>
                <div className={'show-only-mobile mt-32 flex flex-col items-center'}>
                    <PlayStoreButton isWhite={true} />
                    <AppStoreButton />
                </div>
            </motion.div>

            <div
                className="mt-60"
                css={css`
                    background: white;
                `}
            >
                <HighlightBox />
            </div>
        </GridBackgroundStyle>
    );
};

function HighlightBox() {
    return (
        <div css={CONTAINERS.WIDTH_1320_16_PADDING}>
            <div className={'flex gap-20 pt-28 pb-29'} css={gridBox}>
                <FeatureBox
                    css={[
                        css`
                            grid-area: left;
                        `,
                        featureBoxCSS,
                    ]}
                    icon={StarIconBlue}
                    title={() => (
                        <div className="mt-16 text-24  font-600 leading-none md:text-20">
                            <span className=" text-24 font-700 leading-none md:text-24">Rated 4.5+</span>
                        </div>
                    )}
                    desc={() => <div className="text-16 font-400 leading-none text-black-200">on App Store & Play Store</div>}
                />
                <FeatureBox
                    css={[
                        css`
                            grid-area: center;
                        `,
                        featureBoxCSS,
                    ]}
                    icon={UserIcon}
                    title={() => (
                        <div className="text-20  font-600 leading-none md:text-20">
                            <span className="text-24 font-700 leading-none  md:text-24">2,00,000+ users </span>
                        </div>
                    )}
                    desc={() => <div className="text-15 font-400  leading-none text-black-200"> and counting </div>}
                />
                <FeatureBox
                    css={css`
                        grid-area: right;
                        height: 100%;
                        padding: 16px 36px;
                    `}
                    icon={Turnover}
                    title={() => (
                        <div className="text-20  font-600 leading-none md:text-20">
                            <span className="text-24 font-700 leading-none md:text-24">₹ 53,00,00,000+ turnover</span>
                        </div>
                    )}
                    desc={() => <div className="text-15 font-400   leading-none text-black-200">in cash segment NSE/BSE in October 23</div>}
                />
            </div>
        </div>
    );
}

const featureBoxCSS = css`
    height: 100%;
    border-right: 1px solid #e4e4e4;
    padding: 16px 36px;

    @media screen and (max-width: 768px) {
        border-right: none;
    }
`;

const gridBox = css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0;
    grid-template-areas: 'left center right';

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`;

function FeatureBox({ icon = QrCode, title, desc, ...props }: { icon?: any; title: any; desc: any; css: any }) {
    return (
        <motion.div
            initial={{ opacity: 0, x: 12 }}
            animate={{ opacity: 1, x: 0 }}
            className="flex h-full flex-col justify-center py-12 px-30 md:items-center"
            {...props}
        >
            <div className={'flex  justify-center md:justify-center'}>{icon()}</div>
            <div className="mt-20 flex justify-center md:mt-24 md:justify-center">{title()}</div>
            <div className="mt-10 flex justify-center  md:mt-16 md:justify-center">{desc()}</div>
        </motion.div>
    );
}
