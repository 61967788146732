import { css } from '@emotion/react';

export const GridBackgroundStyle = ({ children }) => {
    return <div css={backgroundStyle}>{children}</div>;
};
const backgroundStyle = css`
    width: 100%;

    background-size: 80%;
    background-position-y: -12px;
    background-image: url('/assets/img/marketing/check_desktop.png');
    //transition: background-image 0.2s ease-in-out;

    background-repeat: repeat;
    animation: animatedBackground 40s linear infinite alternate;
    @keyframes animatedBackground {
        from {
            background-position: 0 0;
        }
        to {
            background-position: 0% 100%;
        }
    }

    @media screen and (max-width: 768px) {
        background-image: url('/assets/img/marketing/check_mobile.png') !important;
        background-size: 190%;
    }
`;
