import { css } from '@emotion/react';
import Marquee from 'react-fast-marquee';

import { StarIconBlue } from '@/marketing/constants/icon';

const reviews = [
    {
        review: 'Investmint is changing people’s behaviour in the stock markets, helping them move from random tips to signal based trading.',
        name: 'Kushal Kumar Kanodia',
        designation: 'Lead Consultant - TCS',
        image: '/assets/img/marketing/testimonial/Kushal.jpg',
    },
    {
        review: 'Investmint is an honest platform for retail investors with limited time, and little to no experience in the stock market. If you are new to the market there is no better platform than investmint.',
        name: 'Manohar BS',
        designation: 'Senior Manager, Bureau Veritas Construction',
        image: '/assets/img/marketing/testimonial/Manohar.jpg',
    },
    {
        review: 'I am sure Investmint will certainly be instrumental in shaping retail investor’s behaviour in India when it comes to the stock markets!',
        name: 'Sumit Kumar',
        designation: 'SVP - Xoxoday',
        image: '/assets/img/marketing/testimonial/Sumit Kumar.jpg',
    },
    {
        review: ' Quick short and other models save 100s of minutes in the stock market everyday!',
        name: 'Naveen Kumar',
        designation: 'Data & Growth - PagarBook',
        image: '/assets/img/marketing/testimonial/Naveen.jpg',
    },
    {
        review: 'Amazing achievements! Investmint’s strategies are a game-changer. ',
        name: 'Santosh Singh',
        designation: 'Manager - Euronet Worldwide',
        image: '/assets/img/marketing/testimonial/Santosh.jpg',
    },
    {
        review: " I've always worried about missing out on market opportunities. Investmint’s been a game changer for me. It substitutes complex chart reading, allowing me to sit back and enjoy the investing journey.        ",
        name: 'Rohit Jindal',
        designation: 'Frontend Architect - Kredit.Pe',
        image: '/assets/img/marketing/testimonial/Rohit.jpg',
    },
    {
        review: 'Investmint has transformed me from a losing trader to a profitable systematic trader.',
        name: 'Madhur modi',
        designation: 'Senior manager - ReNew',
        image: '/assets/img/marketing/testimonial/Madhur.jpg',
    },
    {
        review: "With 20 years of trading and investing experience, I cautiously embraced Investmint. Started with small allocations, grew my confidence, and now hold a substantial investment. The team's impressive responsiveness stands out.        ",
        name: 'Sattagouda Patil',
        designation: 'University Finance Faculty         ',
        image: '/assets/img/marketing/testimonial/Sattagouda.jpg',
    },
    // {
    //     review: 'Prior to discovering this app, I kept losing money in the stock market, despite my efforts to analyse charts and strategies. With Investmint app my portfolio consistently stays in the green. Thank you!',
    //     name: 'Sanket Karwa',
    //     designation: 'College Student',
    //     image: '/assets/img/marketing/testimonial/Sanket.jpg',
    // },
];
export const ReviewSection = ({}: any) => {
    return (
        <div css={whiteBG} className={'pt-90 md:pt-72'} id="reviews">
            <Marquee>
                <div className={''} css={gridCSS}>
                    {[...reviews].map((review) => {
                        return <ReviewCard {...review} />;
                    })}
                </div>
            </Marquee>
        </div>
    );
};

const gridCSS = css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    grid-template-rows: 1fr 1fr;
    width: 100vw;
    padding-right: 32px;
    overflow: scroll;
    @media screen and (max-width: 1400px) {
        width: 1400px;
        padding-right: 16px;
    }

    @media screen and (max-width: 768px) {
        /* display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 16px;
        grid-template-rows: 1fr 1fr;
        width: 1400px;
        padding-right: 28px; */
        display: flex;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 16px;
        grid-template-rows: 1fr;
        width: 2600px;
        padding-right: 28px;
        overflow: inherit;
    }
`;

const ReviewCard = ({
    review = 'Loved the app. It has made my investing journey much easier. Recommend this for every DIY investor',
    name = 'Himanshu Dixit',
    designation = 'Investment professional',
    image = '/assets/img/marketing/testimonial/Himanshu.jpg',
}) => {
    return (
        <div css={cardBox} className={'flex flex-col justify-between p-24 px-24'}>
            <div>
                <StarIconBlue />

                <div className={'mt-24 mb-8 text-16 tracking-1'}>{review}</div>
            </div>
            <div className={' mt-20 flex gap-12 text-14 font-400 tracking-2'}>
                <img
                    src={image}
                    css={css`
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                    `}
                />
                <div>
                    <div className="text-18 font-600">{name}</div>
                    {designation}
                </div>
            </div>
        </div>
    );
};

const cardBox = css`
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    flex: 1;
    min-width: 300px;
`;

const whiteBG = css`
    background: #fff;
`;
