import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';

import { MobileBoxWVideo } from '@/marketing/components/homepage/firstSection/allFeaturesList';
import { FeatureIcon, Fingerprint, OneClickConnect, PrivacyIcon, Secure } from '@/marketing/constants/icon';

const isMobileDevice = () => {
    return typeof window !== 'undefined' ? window.innerWidth < 768 : false;
};
export const CardVertical = ({
    label = 'Real-time insights on the stocks you own.',
    subText = 'Get news signals which are likely to impact stock prices',
    bgCSS = pinkColor,
    isBottom = true,
    videoURl = '/assets/videos/combined_1_view_models.mp4',
    cover,
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const isMobile = isMobileDevice();

    const ref = useRef<any>();
    useEffect(() => {
        if (isMobile) {
            ref.current.querySelector('video').play();
            return;
        }
        if (isPlaying) {
            ref.current.querySelector('video').play();
        } else {
            ref.current.querySelector('video').pause();
        }
    }, [isPlaying]);
    return (
        <div
            ref={ref}
            onMouseEnter={() => setIsPlaying(true)}
            onMouseLeave={() => setIsPlaying(false)}
            css={[
                cardBoxVertical,
                bgCSS,
                !isBottom &&
                    css`
                        padding: 0 32px 20px 32px;

                        @media screen and (max-width: 768px) {
                            padding: 0 20px 20px 20px;
                        }
                    `,
            ]}
        >
            {!isBottom && <img loading="lazy" src="/assets/img/marketing/mobile_rewards.png" cover={cover} css={mobileBox} className="mb-20" />}
            <h3 className={'card-heading mt-12 text-center text-36 font-800 leading-1.3 md:px-12'}>{label}</h3>
            <div className="card-subtext md:px-20' mt-12 mb-40 text-center text-22 font-500 md:px-12">{subText}</div>
            {isBottom && <MobileBoxWVideo cover={cover} isPlaying={isPlaying || isMobile} videoURl={videoURl} cropHeight={'390px'} />}
        </div>
    );
};

export const CardItem = ({
    label = 'Real-time insights on the stocks you own.',
    subText = 'Get news signals which are likely to impact stock prices',
    bgCSS = pinkColor,
    isBottom = false,
    videoURl = '/assets/videos/demo.mp4',
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const isMobile = isMobileDevice();

    const ref = useRef<any>();
    useEffect(() => {
        if (isMobile) {
            ref.current.querySelector('video').play();
            return;
        }
        if (isPlaying) {
            ref.current.querySelector('video').play();
        } else {
            ref.current.querySelector('video').pause();
        }
    }, [isPlaying]);
    return (
        <div
            ref={ref}
            onMouseEnter={() => setIsPlaying(true)}
            onMouseLeave={() => setIsPlaying(false)}
            css={[
                cardBox,
                bgCSS,
                !isBottom &&
                    css`
                        padding: 0 64px 40px 64px;

                        @media screen and (max-width: 768px) {
                            padding: 0 20px 40px 20px;
                        }
                    `,
            ]}
        >
            {!isBottom && (
                <div className="mb-60">
                    <MobileBoxWVideo isPlaying={isPlaying || isMobile} videoURl={videoURl} topOffet={-100} cropHeight={'390px'} />
                </div>
            )}
            <h3 className={'card-heading text-36 font-800 leading-1.3'}>{label}</h3>
            <div className="card-subtext mt-12 text-22 font-500">{subText}</div>

            {isBottom && (
                <div className="mt-60 md:mt-24">
                    <MobileBoxWVideo isPlaying={isPlaying || isMobile} videoURl={videoURl} cropHeight={'390px'} />
                </div>
            )}
        </div>
    );
};
const pinkColor = css`
    border: 1px solid rgba(112, 0, 180, 0.4);
    background: #f8f2fb;

    .card-heading {
        color: #7000b4;
    }

    .card-subtext {
        color: #1e1f22;
    }
`;
export const blueColor = css`
    border: 1px solid #5994ec;
    background: #f2f7fe;

    .card-heading {
        color: #035ce2;
    }

    .card-subtext {
        color: #1e1f22;
    }
`;

export const purpleColor = css`
    border: 1px solid #5994ec;
    background: #f2f7fe;

    .card-heading {
        color: #035ce2;
    }

    .card-subtext {
        color: #1e1f22;
    }
`;

export const greenColor = css`
    border: 1px solid #8fd184;
    background: #f3fbf2;

    .card-heading {
        color: #0c6b00;
    }

    .card-subtext {
        color: #1e1f22;
    }
`;
const mobileBox = css`
    height: 400px;
    position: relative;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
`;
const bigCard = css`
    padding: 40px 64px 0px 64px;

    gap: 17px;
    flex: 1 0 0;
    border-radius: 32px;

    @media screen and (max-width: 768px) {
        padding: 40px 20px 0px 20px;
    }
`;

const cardBox = css`
    padding: 40px 64px 0px 64px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 17px;
    flex: 1 0 0;
    border-radius: 32px;

    @media screen and (max-width: 768px) {
        padding: 40px 20px 0px 20px;
    }
`;

const cardBoxVertical = css`
    padding: 32px 20px 0px 20px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 17px;
    flex: 1 0 0;
    border-radius: 32px;

    @media screen and (max-width: 768px) {
        justify-content: center;
        display: flex;
        align-items: center;
        gap: 0;
    }
`;
export const BigCardFeature = ({ label = 'Get realtime news', subText = 'Receive daily alerts', icon }) => {
    return (
        <div css={smallCard} className="h-full">
            {icon ? icon : <FeatureIcon />}
            <div className="mt-16 text-20 font-700 tracking-2">{label}</div>
            <div className="mt-2 text-16 font-400 ">{subText}</div>
        </div>
    );
};
export const BigCardSubFeature = ({ icon, label = 'Get realtime news', subText = '' }) => {
    return (
        <div
            css={[
                smallCard,
                css`
                    padding: 16px 24px;
                    @media screen and (max-width: 500px) {
                        min-width: 100%;
                    }
                `,
            ]}
        >
            {icon ? icon : <FeatureIcon />}
            <div className="mt-20 text-20 font-700 tracking-2">{label}</div>
            <div className="mt-2 text-16 font-400 tracking-2 text-grey-secondary">{subText}</div>
        </div>
    );
};

export const BigCard = ({
    label = 'Buy & sell opportunities on your stocks, now right inside the app.',
    subText = 'Get news signals which are likely to impact stock prices ',
    bgCSS = greenColor,
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const isMobile = isMobileDevice();
    const ref = useRef<any>();
    useEffect(() => {
        if (isMobile) {
            ref.current.querySelector('video').play();
            return;
        }
        if (isPlaying) {
            ref.current.querySelector('video').play();
        } else {
            ref.current.querySelector('video').pause();
        }
    }, [isPlaying]);
    return (
        <div
            ref={ref}
            className=" flex-gap-20 flex  md:flex-col"
            css={[bigCard, bgCSS]}
            onMouseEnter={() => setIsPlaying(true)}
            onMouseLeave={() => setIsPlaying(false)}
        >
            <div className="flex-1">
                <h3 className={'card-heading pt-40 text-36 font-800 leading-1.3 md:pt-12'}>{label}</h3>
                <div className="card-subtext mt-12 text-22 font-500">{subText}</div>

                <div className="mt-40 flex flex-1 flex-wrap items-center justify-center gap-20 pb-32 md:flex-col" css={gridCSS}>
                    <BigCardFeature icon={<OneClickConnect />} label="1-tap sync" subText="we pull directly from 18+ top brokers in India" />
                    <BigCardFeature icon={<Secure />} label="Secure technology" subText="powered by smallcase gateway" />
                    <BigCardFeature icon={<PrivacyIcon />} label="100% data privacy" subText="nobody gets to look at your portfolio except you" />
                    <BigCardFeature icon={<Fingerprint />} label="Unlink anytime" subText="disconnect or delete your data anytime" />
                </div>
            </div>
            <div className="pt-08 flex flex-1 items-end justify-center">
                <MobileBoxWVideo
                    cover={`/assets/img/marketing/portfolio_cover.jpg`}
                    isPlaying={isPlaying || isMobile}
                    videoURl="/assets/videos/portfolio.mp4"
                    cropHeight={'390px'}
                />
            </div>
        </div>
    );
};

export const gridCSS = css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
`;

export const BigCardTime = ({ label = 'Get realtime news' }) => {
    return (
        <div css={timeLineCard}>
            {/* <FeatureIcon /> */}
            <div className=" text-20 font-600 tracking-2">{label}</div>
        </div>
    );
};
export const QuantCardSection = ({ label = 'Quant trading baskets with automated alerts', subText = null, bgCSS = blueColor }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const isMobile = isMobileDevice();

    const ref = useRef<any>();
    useEffect(() => {
        if (isMobile) {
            ref.current.querySelector('video').play();
            return;
        }
        if (isPlaying) {
            ref.current.querySelector('video').play();
        } else {
            ref.current.querySelector('video').pause();
        }
    }, [isPlaying]);
    return (
        <div
            ref={ref}
            className=" flex-gap-20 flex   md:flex-col"
            css={[bigCard, bgCSS]}
            onMouseOver={() => setIsPlaying(true)}
            onMouseLeave={() => setIsPlaying(false)}
        >
            <div className="flex-1">
                <h3 className={' card-heading mt-40 text-36 font-800 leading-1.3 md:mt-0'}>{label}</h3>
                <div className="card-subtext mt-12 text-22 font-500">{subText}</div>

                <div className=" mt-32 flex flex-col gap-12 pb-60">
                    <BigCardTime label={`🚀 Browse 10+ models on the app`} />
                    <BigCardTime label={`⚙️ Select and configure a model to generate signals`} />
                    <BigCardTime label={`📊 View past performance data for your preferences`} />
                    <BigCardTime label={`🔄 Trade buy and sell alerts with your broker through the app`} />
                </div>
            </div>
            <div className="flex flex-1 items-end justify-center">
                <MobileBoxWVideo
                    cover={'/assets/img/marketing/video_cover.png'}
                    isPlaying={isPlaying || isMobile}
                    videoURl="/assets/videos/e2e_video.mp4"
                    cropHeight={'390px'}
                    cover={'/assets/img/marketing/e2e_video.jpg'}
                />
            </div>
        </div>
    );
};

const timeLineCard = css`
    /* border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    background: #ffffff; */

    padding: 12px 20px 0 0px;

    width: 500px;
    max-width: 100%;
`;

const smallCard = css`
    border-radius: 28px;
    border: 1.5px solid rgba(0, 0, 0, 0.12);
    background: #ffffff;
    flex: 1;
    padding: 16px 16px;
    min-width: 200px;
`;
