import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';

import { OGTags } from '@/marketing/constants/ogTags';
import { HomePageScreen } from '@/marketing/screens/homepageScreen';

export default function DashboardPage(props: any) {
    return (
        <div className={'font-gilroy'}>
            <Head>
                <title>Investmint | India's First Signal Based Trading App | Systematic Trading</title>
                <OGTags />
            </Head>
            <HomePageScreen />
        </div>
    );
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
    return {
        props: {},
    };
}
