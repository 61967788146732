import { GlassPattern, PatterBackgroundWithEffect } from '@/marketing/components/common/bg/glassEffect';
import { BigCard, BigCardSubFeature, CardVertical, QuantCardSection, greenColor } from '@/marketing/components/homepage/portfolioSection/components/cards';
import { CardOneIcon, CardThreeIcon, CardTwoIcon } from '@/marketing/constants/icon';
import { CONTAINERS } from '@/marketing/constants/styles';

const QuantCardBox = () => {
    return (
        <>
            <div className="mt-40" id="quant">
                <div className="flex w-full flex-col items-center pt-90   md:pt-20 ">
                    <h2 className="text-56 font-800 leading-1.3 tracking-1 md:text-center md:text-32">Many ways to trade</h2>
                    <p className="mt-8  mb-72 text-24 font-400 text-black-200 md:text-center md:text-16">
                        Discover systematic tools to trade and invest, all by yourself
                    </p>
                </div>
            </div>

            <div className="flex gap-36 pt-0 pb-32 md:flex-col">
                <CardVertical
                    label="Spot Signals"
                    videoURl="/assets/videos/spot_signals.mp4"
                    cover={'/assets/img/marketing/spot_signals.jpg'}
                    subText={
                        <span>
                            Discover big breaking news likely <br /> to impact stock prices
                        </span>
                    }
                />
                <CardVertical
                    label="Broker Signals"
                    videoURl="/assets/videos/broker_signals.mp4"
                    subText="Get buy and sell targets, along with recommendations from 10+ broker sources"
                    bgCSS={greenColor}
                    cover={'/assets/img/marketing/broker_signals_cover.jpg'}
                />
            </div>

            <QuantCardSection />
        </>
    );
};

const PortfolioCards = ({}: any) => {
    return (
        <>
            <div>
                <BigCard label="Experience a 10x better portfolio instantly" subText="Do more, do better with integrated stock portfolios" />
            </div>

            {/* <div className="mt-36 flex gap-36 md:flex-col">
                <CardItem
                    videoURl="/assets/videos/buy_sell.mp4"
                    isBottom={true}
                    label="Real-time insights on the stocks you own"
                    subText="Get news signals which are likely to impact stock prices"
                />
                <CardItem
                    bgCSS={blueColor}
                    isBottom={true}
                    videoURl="/assets/videos/broker_e2e_final.mp4"
                    label="New targets, or buy-sell recommendations"
                    subText="Get real-time research reports from 10+ broker sources"
                />
            </div> */}

            <div className="mt-36 flex flex-1 flex-wrap items-center justify-center gap-36 pb-32 md:flex-col">
                <BigCardSubFeature icon={<CardOneIcon />} label={'Stay on top'} subText="Get curated news on your portfolio" />
                <BigCardSubFeature icon={<CardTwoIcon />} label={`Interested in new stocks?`} subText="Follow stocks outside your portfolio" />
                <BigCardSubFeature icon={<CardThreeIcon />} label={`Take control`} subText="Make real-time data-driven decisions" />
            </div>
        </>
    );
};

export const CardGrid = () => {
    return (
        <>
            <PortfolioCards />
            <QuantCardBox />
        </>
    );
};

export const PortfolioFeatureSection = ({}: any) => {
    return (
        <div className="60" id="portfolio">
            <PatterBackgroundWithEffect pattern={<GlassPattern />}>
                <div css={[CONTAINERS.WIDTH_1320_16_PADDING]}>
                    <div className={'py-24 pb-90 md:pb-52'}>
                        <div className={'flex flex-col md:flex-col'}>
                            <div className="flex w-full flex-col items-center pt-90  pb-8 md:pt-52">
                                <h2 className="text-56 font-800 leading-1.3 tracking-1 md:text-center md:text-32">Investmint 🤝 Your portfolio</h2>
                                <p className="mt-8  mb-72 text-24 font-400 text-black-200 md:mb-60 md:text-center md:text-16">
                                    Rediscover the magic with your existing portfolio
                                </p>
                            </div>

                            <CardGrid />
                        </div>
                    </div>
                </div>
            </PatterBackgroundWithEffect>
        </div>
    );
};
