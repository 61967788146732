import { css } from '@emotion/react';
import { atom, useAtom } from 'jotai';
import { throttle } from 'lodash';
import dynamic from 'next/dynamic';
import { useCallback, useEffect } from 'react';

import { GlassPattern, PatterBackgroundWithEffect } from '@/marketing/components/common/bg/glassEffect';
import { cardContent } from '@/marketing/components/homepage/firstSection/constants';
import { CONTAINERS } from '@/marketing/constants/styles';
import { motion } from 'framer-motion';

const MobileBox = dynamic(() => import('./mobileBox'), { ssr: false });

export const currentFeatureIndexAtom = atom(0);

export const MobileBoxWVideo = ({ cover = null, isPlaying, cropHeight = '500px', topOffet = 0, videoURl = '/assets/videos/subscribed_to_model.mp4' }) => {
    return (
        <div
            css={css`
                max-height: ${cropHeight};
                overflow: hidden;
                top: 20;
                zoom: 1.3;

                @media screen and (max-width: 768px) {
                    zoom: 1;
                }
            `}
        >
            <div
                css={css`
                    background: url('/assets/img/marketing/iPhone.png');
                    height: 600px;
                    width: 300px;

                    max-width: 100%;
                    position: relative;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    overflow: hidden;
                    top: ${topOffet}px;

                    margin: 0 auto;

                    @media screen and (max-width: 768px) {
                        margin: 0 auto;
                    }
                `}
                className="flex flex-col items-center justify-start p-12 px-18 text-20"
            >
                <div
                    className="w-full"
                    css={css`
                        height: 600px;
                        border-radius: 36px;
                        margin-left: -1px;
                        width: calc(100% + 2px);
                        overflow: hidden;
                        @media screen and (max-width: 370px) {
                            width: calc(100% + 10px);
                            margin-top: 2px;
                        }
                    `}
                >
                    <video
                        loop={true}
                        height="600"
                        preload="none"
                        autoPlay={isPlaying}
                        poster={cover}
                        muted={true}
                        css={css`
                            height: auto;
                            border-radius: 32px;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;

                            @media screen and (max-width: 370px) {
                                margin-top: 8px;
                            }
                        `}
                    >
                        <source src={videoURl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
};

export const AllFeaturesList = ({}: any) => {
    const [featureIndex, setFeatureIndex] = useAtom(currentFeatureIndexAtom);

    const getIndexInView = useCallback((data) => {
        function elementInViewport(el) {
            const topOffetToDetectScroll = -100;
            let top = el.offsetTop + topOffetToDetectScroll;
            let left = el.offsetLeft;
            let width = el.offsetWidth;
            let height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top >= window.pageYOffset &&
                left >= window.pageXOffset &&
                top + height <= window.pageYOffset + window.innerHeight &&
                left + width <= window.pageXOffset + window.innerWidth
            );
        }

        cardContent.map((item, index) => {
            const element = document.getElementById(`feature-${index}`);
            if (elementInViewport(element)) {
                setFeatureIndex(index);
            }
        });
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', throttle(getIndexInView, 100));
        return () => {
            window.removeEventListener('scroll', getIndexInView);
        };
    }, []);
    return (
        <div className="mt-60" id="features">
            <PatterBackgroundWithEffect pattern={<GlassPattern />}>
                <div css={[CONTAINERS.WIDTH_1320_16_PADDING]}>
                    <div css={[container]} className={'py-24'}>
                        <div css={patternBorder} className={'flex md:flex-col'}>
                            <div
                                className={'flex-2 show-only-desktop flex flex-col items-center py-32 pt-0'}
                                css={css`
                                    flex: 2;
                                    border-right: 1px dashed #efefef;
                                `}
                            >
                                <MobileBox featureIndex={featureIndex} />
                            </div>
                            <div
                                className={'flex-3 relative px-40 py-80  md:px-12 md:pt-20 md:pb-40'}
                                css={css`
                                    flex: 3;
                                `}
                            >
                                {cardContent.map((item, index) => {
                                    const content = item.content;
                                    return (
                                        <motion.div
                                            initial={{ opacity: 0, right: 200 }}
                                            animate={{ opacity: 1, right: 0 }}
                                            exit={{ opacity: 0, right: 200 }}
                                            whileInView="visible"
                                            viewport={{ once: true }}
                                            key={index}
                                            id={`feature-${index}`}
                                            // className={index < cardContent.length - 1  ? "mb-100" : ""}
                                            css={css`
                                                margin-bottom: ${index < cardContent.length - 1 ? '320px' : '100px'};

                                                @media screen and (max-width: 768px) {
                                                    margin-bottom: 80px;
                                                }
                                            `}
                                        >
                                            <div className="show-only-mobile mb-40">
                                                <MobileBox featureIndex={index} isSticky={false} />
                                            </div>

                                            {content}
                                        </motion.div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </PatterBackgroundWithEffect>
        </div>
    );
};

const patternBorder = css`
    border-top: 1px dashed #efefef;
    border-bottom: 1px dashed #efefef;

    @media screen and (max-width: 768px) {
        border-top: none;
        border-bottom: none;
    }
`;

const container = css`
    border-right: 1px solid #efefef;
    border-left: 1px solid #efefef;
`;
