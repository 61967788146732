import { AnnouncementBar } from '@/marketing/components/homepage/announcementBar';
import { FAQSection } from '@/marketing/components/homepage/faq';
import { AllFeaturesList } from '@/marketing/components/homepage/firstSection/allFeaturesList';
import { FooterSection } from '@/marketing/components/homepage/footer';
import { HeroSection } from '@/marketing/components/homepage/hero';
import { MintRewardSection } from '@/marketing/components/homepage/mintRewards/featuresSection';
import { PortfolioFeatureSection } from '@/marketing/components/homepage/portfolioSection/PortfolioSection';
import { ReviewSection } from '@/marketing/components/homepage/reviewSection/reviewSection';
import { TrustSection } from '@/marketing/components/homepage/trustSection/trustSection';

export const HomePageScreen = () => {
    return (
        <>
            {/* <AnnouncementBar text="🇮🇳 Investmint launches Green account. ₹10 per order, save 50% broker fees." linkText="Register" linkUrl='/green' /> */}
            <HeroSection />
            <AllFeaturesList />
            <PortfolioFeatureSection />
            {/* <ModelSection /> */}
            <TrustSection />
            {/* <PricingSection /> */}
            <ReviewSection />
            <MintRewardSection />
            {/* <FAQSection /> */}
            <FooterSection />
        </>
    );
};
