import { css } from '@emotion/react';

import { QRScanBox } from '@/marketing/components/common/qrScanBox';
import { AppStoreButton, PlayStoreButton } from '@/marketing/components/homepage/hero/button';
import { CONTAINERS } from '@/marketing/constants/styles';

export const MintRewardSection = ({}: any) => {
    return (
        <div css={whiteBG} id="mint_reward" className="py-88 pb-0">
            <div css={CONTAINERS.WIDTH_1320_16_0_PADDING}>
                <div css={blockCSS} className="flex flex-col items-center py-32 text-center">
                    <div css={border} className="h-full w-full">
                        <div className={'flex flex-col items-center pt-90  pb-8 md:pt-52'}>
                            <h2 className={'px-12 text-56 font-800 leading-1.3 tracking-1 md:text-center md:text-32'}>Get rewarded with stocks</h2>
                            <p className="mt-4 px-20 text-center text-24 font-400 leading-1.9 md:text-20">Trade news and quant signals to earn mint coins</p>
                            <p className="mt-4 px-20  text-center text-24  font-400 leading-1.9 md:text-20">
                                Redeem them to get stocks like MRF, MARUTI, SBIN and many more
                            </p>

                            <div className="mt-24 flex flex-col items-center md:hidden">
                                <QRScanBox completeWhite={true} />
                            </div>
                            <div className={'show-only-mobile mt-24 flex w-full flex-col items-center px-12'}>
                                <PlayStoreButton isWhite={true} />
                                <AppStoreButton isWhite={true} />
                            </div>
                        </div>

                        <div
                            className="show-only-mobile w-full text-center"
                            css={css`
                                max-width: 100vw;
                            `}
                        >
                            <div
                                className="mt-40 flex gap-40"
                                css={css`
                                    top: 40%;
                                    transform: translateY(-50%);
                                    left: -50px;
                                    height: 220px;
                                    background: url('/assets/img/marketing/card_strip.png') repeat-x;

                                    background-size: contain;

                                    width: 7680px;
                                    animation: slide 60s linear infinite;

                                    @keyframes slide {
                                        0% {
                                            transform: translate(0);
                                        }
                                        100% {
                                            transform: translate(-3840px); /* The image width */
                                        }
                                    }
                                `}
                            />
                            <img
                                loading="lazy"
                                src="/assets/img/marketing/mobile_rewards.png"
                                css={[
                                    mobileBox,
                                    css`
                                        margin: 52px auto 0 auto;
                                    `,
                                ]}
                                className="mt-60"
                            />
                        </div>
                        <div className=" show-only-desktop relative flex w-full justify-center text-center">
                            <div
                                className="absolute flex gap-40"
                                css={css`
                                    top: 25%;
                                    transform: translateY(-50%);
                                    left: -50px;
                                    height: 220px;
                                    background: url('/assets/img/marketing/card_strip.png') repeat-x;

                                    background-size: contain;

                                    width: 7680px;
                                    animation: slide 60s linear infinite;

                                    @keyframes slide {
                                        0% {
                                            transform: translate(0);
                                        }
                                        100% {
                                            transform: translate(-3840px); /* The image width */
                                        }
                                    }
                                `}
                            >
                                {/* <img loading="lazy" src="/assets/img/marketing/card.png" css={cardBox} />
                                <img loading="lazy" src="/assets/img/marketing/card.png" css={cardBox} />
                                <img loading="lazy" src="/assets/img/marketing/card.png" css={cardBox} />
                                <img loading="lazy" src="/assets/img/marketing/card.png" css={cardBox} />
                                <img loading="lazy" src="/assets/img/marketing/card.png" css={cardBox} />
                                <img loading="lazy" src="/assets/img/marketing/card.png" css={cardBox} />
                                <img loading="lazy" src="/assets/img/marketing/card.png" css={cardBox} /> */}
                            </div>
                            <img loading="lazy" src="/assets/img/marketing/mobile_rewards.png" css={mobileBox} className="mt-60" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const cardBox = css`
    height: 211px;
`;

const mobileBox = css`
    height: 400px;
    position: relative;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
`;

const qrScanBox = css`
    color: #2c2b2b;
`;

const blockCSS = css`
    width: 100%;

    flex-shrink: 0;
    border-radius: 32px 32px 0px 0px;
    color: #fff;

    background: linear-gradient(180deg, #090808 0%, #161212 100%), linear-gradient(180deg, #fefefe 29.69%, #fcfcfc 74.23%);

    padding-bottom: 0px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        border-radius: 0px 0px 0px 0px;
    }
`;

const border = css`
    border-top: 1px dashed #2c2b2b;
    border-bottom: 1px dashed #2c2b2b;
`;

const whiteBG = css`
    background: #fff;
`;
