import { css } from '@emotion/react';

export const PatterBackgroundWithEffect = ({ children, pattern, ...props }: any) => {
    return (
        <div className="relative">
            <div className="w-full">{pattern}</div>
            <div className=" w-full" css={glassEffectCSS} {...props}>
                {children}
            </div>
        </div>
    );
};
export const GlassPattern = () => (
    <>
        <div
            className="absolute"
            css={css`
                width: 40px;
                height: 40px;
                background: #ff7dcb;
                top: 40px;
                left: 15%;
            `}
        ></div>
        <div
            className="absolute"
            css={css`
                width: 40px;
                height: 40px;
                background: #ff867d;
                top: 80px;
                left: 48%;
            `}
        ></div>

        <div
            className="absolute"
            css={css`
                width: 40px;
                height: 40px;
                background: #7dadff;
                top: 60px;
                left: 78%;
            `}
        ></div>
    </>
);
const glassEffectCSS = css`
    border: 1px solid rgba(232, 229, 229, 0.62);
    background: rgba(255, 255, 255, 0.387);
    backdrop-filter: blur(16px);
    height: fit-content;
`;
